<template>
  <div class="wrapper">
    <div class="page-header error-page">
      <div
        class="page-header-image"
        :style="
          `background-image: url(${require('@/assets/img/ill/404.svg')});`
        "
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1 class="title">404</h1>
            <p class="lead">Page not found :(</p>
            <h4 class="description text-default">
              Ooooups! Looks like you got lost.
            </h4>
            <p>
              <router-link to="/"
                ><base-button outline
                  >Home <span class="fa fa-home"></span></base-button
              ></router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: () => ({
    title: "404 Not Found"
  })
};
</script>
<style></style>
